<template>
  <div class="row">
    <div class="col-12 d-none d-sm-none d-md-block">

        <div class="breadcrumb-main">
            <h4 class="text-capitalize breadcrumb-title">{{namePage}}</h4>
      
            
            <div class="card-extra">
                <div class="row" style="min-width: 432px;">
                  <div class="col-12 col-md-6">
                    <div v-if="dateTypeData=='DAY'"  class="action-btn">
                        <div class="form-group mb-0">
                            <div class="input-container icon-left position-relative">
                                <span class="input-icon icon-left">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                </span>
                                <input @change="changeDateToday" ref="dateTodayRef" v-model="dateTodayData" type="date" class="form-control form-control-default date-ranger" name="date-ranger" placeholder="Oct 30, 2019 - Nov 30, 2019">
                             
                            </div>
                        </div>
                    </div>
                    <div v-if="dateTypeData=='MONTH'"  class="action-btn">
                        <div class="form-group mb-0">
                            <div class="input-container icon-left position-relative">
                                <span class="input-icon icon-left">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                </span>
                                <input @change="changeDateMonth" ref="dateMonthRef" v-model="dateMonthData" type="month" class="form-control form-control-default date-ranger" name="date-ranger" placeholder="Oct 30, 2019 - Nov 30, 2019">
                            
                            </div>
                        </div>
                    </div>
                    <div v-if="dateTypeData=='YEAR'" class="action-btn">
                        <div class="form-group mb-0">
                            <div class="input-container icon-left position-relative">
                                <span class="input-icon icon-left">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                </span>
                                <select @change="changeDateYear" v-model="dateYearData" ref="dateYearRef" class=" select-year form-control form-control-default date-ranger" name="date-ranger" placeholder="Oct 30, 2019 - Nov 30, 2019">
                                  <option v-for="e in arrayYearData" :key="e" :value="e">{{e}}</option>
                                </select>
                               
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 p-0">
                    <div class="card-tab btn-group nav nav-tabs">
                        <a v-if="!hideToday" @click="changeType('DAY')"   :class="`btn btn-xs btn-white border-light ${dateTypeData=='DAY'?'active':''} `" id="f_today-tab" data-toggle="tab" href="#f_overview-today" role="tab" area-controls="f_overview" aria-selected="false">Today</a>
                        <a v-if="!hideMonth" @click="changeType('MONTH')" :class="`btn btn-xs btn-white border-light ${dateTypeData=='MONTH'?'active':''} `" id="f_month-tab" data-toggle="tab" href="#f_overview-month" role="tab" area-controls="f_overview" aria-selected="false">Month</a>
                        <a v-if="!hideYear"  @click="changeType('YEAR')"  :class="`btn btn-xs btn-white border-light ${dateTypeData=='YEAR'?'active':''} `"  id="f_year-tab" data-toggle="tab" href="#f_overview-year" role="tab" area-controls="f_overview" aria-selected="true">Year</a>
                    </div>
                  </div>
                </div>
                
            </div>
        </div>

    </div>
    <div class="col-12 d-md-none card-bread pt-3 p-1 ">
      <div class="row" style=" ">
        <div class="col-6">
          <div v-if="dateTypeData=='DAY'"  class="action-btn">
              <div class="form-group mb-0">
                  <div class="input-container icon-left position-relative">
                
                      <input @change="changeDateToday" ref="dateTodayRef" v-model="dateTodayData" type="date" class="form-control form-control-default date-ranger" name="date-ranger" placeholder="Oct 30, 2019 - Nov 30, 2019">
                    
                  </div>
              </div>
          </div>
          <div v-if="dateTypeData=='MONTH'"  class="action-btn">
              <div class="form-group mb-0">
                  <div class="input-container icon-left position-relative">
                 
                      <input @change="changeDateMonth" ref="dateMonthRef" v-model="dateMonthData" type="month" class="form-control form-control-default date-ranger" name="date-ranger" placeholder="Oct 30, 2019 - Nov 30, 2019">
                  
                  </div>
              </div>
          </div>
          <div v-if="dateTypeData=='YEAR'" class="action-btn">
              <div class="form-group mb-0">
                  <div class="input-container icon-left position-relative">
                     
                      <select @change="changeDateYear" v-model="dateYearData" ref="dateYearRef" class=" select-year form-control form-control-default date-ranger" name="date-ranger" placeholder="Oct 30, 2019 - Nov 30, 2019">
                        <option v-for="e in arrayYearData" :key="e" :value="e">{{e}}</option>
                      </select>
                      
                  </div>
              </div>
          </div>
        </div>
        <div class="col-6 ">
          <div class="card-tab btn-group nav nav-tabs">
              <a v-if="!hideToday" @click="changeType('DAY')"    :class="`btn btn-xs btn-white border-light ${dateTypeData=='DAY'?'active':''} `" id="f_today-tab" data-toggle="tab" href="#f_overview-today" role="tab" area-controls="f_overview" aria-selected="false">Today</a>
              <a v-if="!hideMonth" @click="changeType('MONTH')" :class="`btn btn-xs btn-white border-light ${dateTypeData=='MONTH'?'active':''} `" id="f_month-tab" data-toggle="tab" href="#f_overview-month" role="tab" area-controls="f_overview" aria-selected="false">Month</a>
              <a v-if="!hideYear"  @click="changeType('YEAR')"  :class="`btn btn-xs btn-white border-light ${dateTypeData=='YEAR'?'active':''} `"  id="f_year-tab" data-toggle="tab" href="#f_overview-year" role="tab" area-controls="f_overview" aria-selected="true">Year</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as serviceMain  from '@/services/main.service';
export default {
  name: 'BreadCrumbDate',
  props: [
    "defaultType",
    "namePage",
    "hideToday",
    "hideMonth",
    "hideYear"
  ],
  data: function () {
    return {
      dateTodayData:'',
      dateMonthData:'',
      dateYearData:'',
      dateTypeData:'DAY',
      arrayYearData:[]
    }
  },
  created() {
    this.getDate()
  },
  methods: {
    async getDate(){
      if(this.defaultType){
        this.dateTypeData = this.defaultType
      }
      let dateObj = new Date();
      let day = dateObj.getUTCDate();
      if(day<10){
        day = `0${day}`
      }
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      if(month<10){
        month = `0${month}`
      }
      
      let year = dateObj.getUTCFullYear();
      let getTypeToday = `${year}-${month}-${day}`
      let getTypeMonth = `${year}-${month}`
      let getTypeYear = `${year}`
      
      let arrayYear = []
      for (let i = 1900; i < 2200; i++) {
        arrayYear.push(i)
      }
      this.arrayYearData = arrayYear
      this.dateTodayData = getTypeToday;
      this.dateMonthData = getTypeMonth;
      this.dateYearData = getTypeYear;
      this.$store.dispatch('localstorage/SET_dateToday',getTypeToday);
      this.$store.dispatch('localstorage/SET_dateMonth',getTypeMonth);
      this.$store.dispatch('localstorage/SET_dateYear',getTypeYear);
      this.$store.dispatch('localstorage/SET_dateType',this.dateTypeData);
    },
   
    changeDateToday(){
      this.$store.dispatch('localstorage/SET_dateToday',this.dateTodayData);
    },
    changeDateMonth(){
      this.$store.dispatch('localstorage/SET_dateMonth',this.dateMonthData);
    },
    changeDateYear(){
      this.$store.dispatch('localstorage/SET_dateYear',this.dateYearData);
    },
    changeType(para_type){
      this.dateTypeData = para_type
      this.$store.dispatch('localstorage/SET_dateType',para_type);
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_dateToday:function(){
      return this.$store.getters['localstorage/GET_dateToday']
    },
    GET_dateMonth:function(){
      return this.$store.getters['localstorage/GET_dateMonth']
    },
    GET_dateYear:function(){
      return this.$store.getters['localstorage/GET_dateYear']
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.card-tab{
  height:100% ;
  
}
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show > .btn-white.dropdown-toggle {
  background: rgb(47,85,164);
  background: linear-gradient(180deg, rgba(47,85,164,1) 0%, rgba(53,43,108,1) 100%);
  color: white;
}
.select-year{
  padding: 5px 0px 5px 40px;
}
.card-bread{
  background-color: white;
  
}
@media only screen and (max-width: 768px) {
    .btn{
        padding: 0px;
    }   
    .input-container.icon-left input{
      padding: 5px;
    }

}
</style>
