<template>
  <canvas ref="ChartRef"  width="100%" height="100%"></canvas>
</template> 

<script>
export default {
  name: 'ChartJs',
  components: {

  },
  props: [
    "type",
    "data",
    "option" 
  ],
  data: function () {
    return {
      ChartData:{
        labels: ["0s", "10s", "20s", "30s", "40s", "50s", "60s"],
        datasets: this.dataset 
      },
      ChartOptions:{
        responsive: true,
        elements: {
            point:{
                radius: 0
            }
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle:true,
            fontColor: '#5A5F7D',
            color:true
          }
        }
      }
    }
  },

  mounted() {
    this.genChart()
  },
  methods: {
    async genChart(){
      let _this = this
      // let ctx = document.getElementById(getuuid).getContext('2d')
      let ctx = _this.$refs.ChartRef.getContext('2d')
      let bar = new Chart(ctx,{
        type: this.type,
        options: _this.option,
        data: _this.data
      });
    }
  },

}
</script>

