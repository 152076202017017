<template>
<div class="row mt-4">
    <div class="col-12">
        <div @click="isHover = !isHover"  class="pointer card border-blue">
            <div class="card-body py-2 position-relative">
                <div class="row">
                  <div class="col-12">
                    <a >
                      <p class="title-p text-center m-0">
                        <img src="/images/icon/building-line.png" alt="">
                        <span class="title-name">
                          เลือกสาขา
                        </span>
                      </p>
                    </a>
                  </div>
                </div>
                
            </div>
        </div>
        <div v-if="isHover" class=" list-plant">
          <div class="row card">
            <div class="col-12 p-0">
              <div v-for="e in listData" @click="selectPlant(e)" :key="e.site_id" class="d-flex pointer plant-card justify-content-start">
                <div>
                  <img class="img-plant p-0 m-0" :src="e.image" alt="img-plant" >
                </div>
                <div class="text-plant">
                  <p class="text-truncate">{{e.plant_name}}</p>
                  <p>{{e.site_id}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>

</template>

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
export default {
  name: 'SelectPlant',
  data: function () {
    return {
        listData:[],
        isHover:false,
    }
  },
  mounted() {
     this.getlistData()
  },
  methods: {
    async getlistData(){
      try {
        let data = {
          COM_ID:this.$store.getters['auth/getUser'].COMPANY_ID
        }
        let getAPI = await serviceAPI.call_API('get','main/GetPlantSiteIdByCompanyId',data,'auth');
        this.listData = getAPI.data.data
        // image:"https://liff.ecotechpart.com/images/ecotechwhite.png"
        // inverterid:3
        // invertername:"SolarEdge"
        // plant_name:"I.P. Tranding Co.,Ltd."
        // site_id:1340607
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async selectPlant(e){

      this.$store.dispatch('localstorage/SET_plantId',e.site_id)
      this.$store.dispatch('localstorage/SET_inverterId',e.inverterid)
      this.isHover=false;
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_plantId:function(){
      return this.$store.getters['localstorage/GET_plantId']
    },
    
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.border-blue{
  border:2px #352B6C solid; 
}
.list-plant{
  position:absolute;
  top: 45px;
  left: 0px;
  width: 100%;
  padding: 0px 30px;
  z-index: 3;
  
}
.plant-card{
  padding: 15px;
}
.border-blue{
  border:2px #352B6C solid;
}
.img-plant{
  width: 70px;
  border:1px #F1F2F6 solid ;
  border-radius: 8px;
}
.text-plant{
  padding-left : 15px;
  overflow: hidden;
}
</style>
